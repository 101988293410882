var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      ref: "component",
      staticClass: "InlineField__Component",
      class: {
        "has-value": _vm.inputVal !== null,
        invalid: _vm.required && !_vm.inputVal,
        "read-only": _vm.readOnly,
        "hide-hover": _vm.hideHover
      },
      on: {
        mouseover: function($event) {
          return _vm.hover(true)
        },
        mouseleave: function($event) {
          return _vm.hover(false)
        }
      }
    },
    [
      _c("label", [
        _c("div", { staticClass: "InlineField__Component__Placeholder" }, [
          _c(
            "span",
            { staticClass: "InlineField__Component__Placeholder__Text" },
            [_vm._v(_vm._s(_vm.label))]
          ),
          _c(
            "span",
            { staticClass: "InlineField__Component__Placeholder__Icon" },
            [_c("b-icon", { attrs: { icon: "pencil", size: "is-small" } })],
            1
          )
        ]),
        _c("textarea", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.inputVal,
              expression: "inputVal"
            }
          ],
          attrs: { readonly: _vm.readOnly, required: _vm.required, dir: "rtl" },
          domProps: { value: _vm.inputVal },
          on: {
            focus: function($event) {
              return _vm.focus(true)
            },
            blur: function($event) {
              return _vm.focus(false)
            },
            input: [
              function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.inputVal = $event.target.value
              },
              function($event) {
                return _vm.$emit("onChange")
              }
            ]
          }
        })
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }